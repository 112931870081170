import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../Css/Review.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import R1 from '../assets/R1.webp';
import R2 from '../assets/R2.webp';
import R3 from '../assets/R3.webp';
import R4 from '../assets/R4.webp';
import R5 from '../assets/R5.webp';
import R6 from '../assets/R6.webp'

// Sample review data
const reviewsData = [
  {
    id: 1,
    name: 'Akash Gawande',
    image:R1,
    position: 'TechTribe Inc.',
    review:"Working with Parag Kosurkar Industries has been a transformative experience for our company. Their innovative solutions in SAP have streamlined our operations, making us more efficient and effective. The team was incredibly knowledgeable and responsive, ensuring our needs were met at every step."
  },
  {
    id: 2,
    name: 'Pooja Mishra',
    image: R2,
    position: 'Vertex Systems',
    review: "Parag Kosurkar Industries exceeded our expectations with their top-notch IT services. The custom software they developed for us has significantly boosted our productivity. The entire process was smooth, and their support team was always available to help with any issues."
  },
  {
    id: 3,
    name: 'Diya Wankhede',
    image:R3,
    position: 'NextGen Systems',
    review: "The digital marketing strategies implemented by Parag Kosurkar Industries have dramatically increased our online presence. Their team's creativity and understanding of SEO were instrumental in achieving our goals. We saw a notable increase in traffic and engagement."},
  {
    id: 4,
    name: 'Vedant Bele',
    image:R4,
    position: 'Innovatech Solutions',
    review: "We partnered with Parag Kosurkar Industries for a complex data analytics project, and they delivered outstanding results. Their expertise in the field is evident, and their dedication to understanding our business needs was impressive. I highly recommend their services."
  },
  {
    id: 5,
    name: 'Rohini Bante',
    image: R5,
    position: 'EliteTech Services',
    review:"I can't thank Parag Kosurkar Industries enough for their support in developing our mobile application. Their attention to detail and commitment to quality ensured a successful launch. The app has received fantastic feedback from our users, and it’s all thanks to their hard work."
  },
  {
    id: 6,
    name: 'Aman Kamble',
    image: R6,
    position: 'BluePeak Solutions',
    review: "Parag Kosurkar Industries provided us with excellent SAP solutions tailored to our specific industry needs. Their consultants were highly skilled and professional, delivering on time and within budget. We are thrilled with the improvements in our business processes."}
];

// Review Card Component
const ReviewCard = ({ review }) => {
  return (
    <div className="review-card">
      <img src={review.image} alt={`${review.name}'s photo`} />
      <h3>{review.name}</h3>
      <p className="position">{review.position}</p>
      <p className="phone">{review.phone}</p>
      <p className="review">{review.review}</p>
    </div>
  );
};

// Main Review Section Component
const EmployeeReview = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(reviewsData);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <div className="review-section">
      <h2>Client's Review</h2>
      <Slider {...settings} className="review-slider">
        {reviews.map((review) => (
          <ReviewCard key={review.id} review={review} />
        ))}
      </Slider>
    </div>
  );
};

export default EmployeeReview;
