import React from 'react';
import '../ItServices/SoftwareDev.css';
import SDIMG from '../../../assets/SoftwareDev.webp'
import SD0 from '../../../assets/SD0.svg'
import SD1 from '../../../assets/SD1.webp'
import SD2 from '../../../assets/SD2.webp'
import SD3 from '../../../assets/SD3.webp'
import SD4 from '../../../assets/SD4.webp'
import SD5 from '../../../assets/SD5.webp'
import { useEffect } from 'react';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
const FeatureCards = () => {
    const features = [
      { title: 'Software Consulting', info: 'With proven capabilities, our subject-matter experts will assess your problem and come up with possible software solutions. They’ll advise on techs and architecture design, as well as provide the scalability and security roadmaps, a rough estimation of time and costs, and more.' },
      { title: 'End-To-End Software Development', info: 'With proven capabilities, our subject-matter experts will assess your problem and come up with possible software solutions. They’ll advise on techs and architecture design, as well as provide the scalability and security roadmaps, a rough estimation of time and costs, and more.' },
      { title: 'Legacy Software Modernization', info: 'Intelligent bots programmed to automate multiple business processes and customer service with instant, precise information.' },
      { title: 'Integration', info: 'We can integrate new software with other tools you already use – be they custom-made or platform-based – like ERP, CRM, PDM, MES, vendor and customer portals, and more.' },
      { title: 'API Development', info: 'Our experts can build clean, secure and well-documented APIs to help you extend enterprise-wide automation or enable smooth and easy integration of your custom solution with other internal or external systems and subsystems.' },
    ];
    const pointsLeft = [
        'Big Data',
        'Artificial Intelligence',
        'Data Science',
        'Internet Of Things'
      ];
    
      const pointsRight = [
        'Augmented Reality',
        'Blockchain',
        'Computer Vision'
      ];
      const units = [
        { heading: 'Manufacturing', content: 'Applications that ensure effective factory management, mitigate the risks of equipment failures, reduce operational costs, improve performance, establish smooth supply and distribution, and boost communication with partners.', image: SD1 },
        { heading: 'Healthcare', content: 'Applications that built in line with government regulations (HIPAA, HITECH, ACA, FDASIA, and others) and protocols (HL7, ANSI X12, DICOM, ICD-10, and others). In March 2018, we officially proved our processes are compliant with ISO 13485:2016.', image: SD2 },
        { heading: 'Retail', content: 'Solutions for large, mid-sized and small retail businesses that lead to effective management, improved vendor-partner communication, increased sales, and stronger customer loyalty.', image: SD3 },
        { heading: 'Banking and financial services', content: 'Applications that improve operational efficiency, ensure internal information consistency, and facilitate clients’ communication with the bank.', image: SD4 },
        { heading: 'Telecommunications', content: 'Applications that bring communication to the next level and contribute to its security: Business Support Systems, customized Value-Added Services software and big data analytics tools.', image: SD5 },
      ];
      useEffect(() => {
        Events.scrollEvent.register('begin', function(to, element) {
          console.log('begin', arguments);
        });
    
        Events.scrollEvent.register('end', function(to, element) {
          console.log('end', arguments);
        });
    
        scrollSpy.update();
    
        return () => {
          Events.scrollEvent.remove('begin');
          Events.scrollEvent.remove('end');
        };
      }, []);
    
      const handleScrollAnimation = () => {
        const section = document.querySelector('.custom-solutions-section');
        const sectionPos = section.getBoundingClientRect().top;
        const screenPos = window.innerHeight / 1.2;
    
        if (sectionPos < screenPos) {
          section.classList.add('animate');
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScrollAnimation);
        return () => {
          window.removeEventListener('scroll', handleScrollAnimation);
        };
      }, []);
    

      const getColorByIndex = (index) => {
        switch(index) {
          case 0:
            return '#f03304';
          case 1:
            return '#08f385e8';
          case 2:
            return '#99f308e8';
            case 3: 
            return '#f30843e8';
          default:
            return '#b25ff5dc'; // Default color for remaining units
        }
      };
      return (
      
          <>
          <img className='SDIMG' src={SDIMG}/>

            <div className="custom-solutions-section">
        <div className="left-side">
          <h3>CUSTOM SOFTWARE DEVELOPMENT SERVICES</h3>
          <p>Custom software development is the process of designing, building, integrating, scaling, and upgrading software solutions to address the pressing needs or achieve objectives of your specific business. Parag Kosurkar Industries delivers high-grade custom software to a wide range of clients – from Fortune 100 companies to mid-sized businesses. Distilling 8 years of experience in IT, expertise across various technology stacks and in 26 industries, we can help you to solve complex challenges with reliable and agile digital solutions.</p>
        </div>
        <div className="right-side">
          <h3>TAILORED SOLUTIONS FOR EVERY TYPE OF BUSINESS PROCESS</h3>
          <p>We deliver custom web, mobile and desktop software solutions that broadly fall under 3 main categories – management of B2B, B2C interactions and internal operations. Our software confidently works across all popular browsers, OSes and mobile platforms, scales to millions of users and delivers immaculate UX through a clear, logical layout and smooth workflows.</p>
        </div>
      </div>

          <div className="feature-cards-container">
           
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <h3>{feature.title}</h3>
                <p>{feature.info}</p>
              </div>
            ))}
          </div>
          
          <div className="info-container">
            <div className="info-text">
              <h2>We Help Our Customers To Innovate & Influence.</h2>
              <div className="points-container">
                <div className="points-left">
                  {pointsLeft.map((point, index) => (
                    <div key={index} className="black-container point-item">
                      <span className="tick-icon">✔</span>
                      {point}
                    </div>
                  ))}
                </div>
                <div className="points-right">
                  {pointsRight.map((point, index) => (
                    <div key={index} className="black-container point-item">
                      <span className="tick-icon">✔</span>
                      {point}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="info-image">
              <img src={SD0} alt="Expertise" />
            </div>
          </div>

          <div className="scroll-container">
          {units.map((unit, index) => (
          <div key={index} className="unit-container">
            <div className="half-container left-content">
              <div className="content">
                {/* Content for the left side */}
                <h3>{unit.heading}</h3>
                <p>{unit.content}</p>
              </div>
            </div>
            <div className="half-container right-image" style={{ backgroundColor: getColorByIndex(index),    position: 'relative', // Ensures the container can be filled by the image
    overflow: 'hidden',  }}>
              <img src={unit.image} alt={unit.heading}  style={{
      width: '100%', // Ensures the image fills the width of the container
      height: '100%', // Ensures the image fills the height of the container
      objectFit: 'cover', // Ensures the image covers the container without distortion
      position: 'absolute', // Allows positioning the image correctly
      top: 0, // Aligns the image to the top of the container
      left: 0, // Aligns the image to the left of the container
    }} />
            </div>
          </div>
        ))}
      </div>

      </>
        
      );
    };
    
    export default FeatureCards;