import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img from '../AboutPages/about-images/choose_us.png';
import anotherImg from '../AboutPages/about-images/why.png'; // Replace with your actual image path
import { FaFontAwesomeFlag, FaUserCog, FaUsers, FaRegStar } from 'react-icons/fa';

const WhyChooseUs = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <div>
     <div>
  <div style={styles.whyChooseUs}>
    <div style={styles.imageContainer}>
      <img src={img} alt="Background" style={styles.image} />
      <div style={styles.overlay}></div> {/* Dark Overlay */}
      <div style={styles.overlayText}>
        <h1 style={styles.paragraph1}>Why Choose Us</h1>
        <p style={styles.paragraph}>
          We specialize in delivering enterprise-level support worthy of a Fortune 100 company
        </p>
      </div>
    </div>
  </div>
</div>

  

      <div style={styles.cardSection}>
        <div style={styles.cardGrid}>
          <div
            style={hoveredCard === 0 ? { ...styles.card, ...styles.cardHover } : styles.card}
            onMouseEnter={() => setHoveredCard(0)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <FaFontAwesomeFlag style={styles.icon} />
            <p style={styles.cardhead}>Commitment</p>
            <p style={styles.cardText}>Be accountable, work together as a team and communicate clearly.</p>
          </div>
          <div
            style={hoveredCard === 1 ? { ...styles.card, ...styles.cardHover } : styles.card}
            onMouseEnter={() => setHoveredCard(1)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <FaUserCog style={styles.icon} />
            <p style={styles.cardhead}>Innovation</p>
            <p style={styles.cardText}>Think outside the box, challenge constructively and act before others do.</p>
          </div>
          <div
            style={hoveredCard === 2 ? { ...styles.card, ...styles.cardHover } : styles.card}
            onMouseEnter={() => setHoveredCard(2)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <FaUsers style={styles.icon} />
            <p style={styles.cardhead}>Community</p>
            <p style={styles.cardText}>Creating a positive place to work and supporting ethical initiatives.</p>
          </div>
          <div
            style={hoveredCard === 3 ? { ...styles.card, ...styles.cardHover } : styles.card}
            onMouseEnter={() => setHoveredCard(3)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <FaRegStar style={styles.icon} />
            <p style={styles.cardhead}>Excellence</p>
            <p style={styles.cardText}>Always deliver exceptional quality, accomplish and improve.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  whyChooseUs: {
    position: 'relative',
    width: '100%',
    height: '700px', // Adjust height as needed
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center horizontally
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '90%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '90%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker overlay
    zIndex: 1,
  },
  overlayText: {
    position: 'relative',
    color: 'white',
    zIndex: 2,
    textAlign: 'center', // Center text horizontally
    padding: '20px', // Adjust padding as needed
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '90%',
  },
  paragraph1: {
    margin: '0',
    fontSize: '2.5rem',
    opacity: 0,
    animation: 'fadeIn 1s ease-in-out forwards',
    animationDelay: '1s',
  },
  paragraph: {
    margin: '10px 0 0 0',
    fontSize: '1.5rem',
    opacity: 0,
    animation: 'fadeIn 1s ease-in-out forwards',
    animationDelay: '1s',
  },
  
  cardSection: {
    padding: '50px 20px',
    backgroundColor: '#f8f9fa',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '20px',
    padding: '0 20px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.8s ease-in-out, box-shadow 0.3s ease-in-out',
  },
  cardHover: {
    transform: 'translateY(-10px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    backgroundColor:  '#96C8FB',
    color: 'white',
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '10px',
  },
  cardhead: {
    fontSize: '1.8rem',
  },
  cardText: {
    fontSize: '1rem',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

const mediaStyles = {
  '@media (max-width: 768px)': {
    whyChooseUs: {
      height: '400px', // Adjust height for mobile
    },
    image: {
      height: '100%', // Ensure the image covers the full height on mobile
    },
    overlay: {
      height: '100%', // Ensure the overlay covers the full height on mobile
    },
    overlayText: {
      padding: '10px', // Reduce padding for mobile
    },
    paragraph1: {
      fontSize: '1.5rem', // Adjust font size for mobile
    },
    paragraph: {
      fontSize: '1rem', // Adjust font size for mobile
    },
  },
};

// Combine styles and mediaStyles
const combinedStyles = { ...styles, ...mediaStyles };

export default WhyChooseUs;