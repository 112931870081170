import React from "react";
import backImg from "../../assets/BankFinance/health.webp";
import "../../Css/Healthcare.css";
import { bank } from "../../assets/BankFinance/bank";

const cards = [
  {
    id: 1,
    title: "Connect huge DataSelf-Serve and simple Reporting",
    description:
      "Parag Kosurkar Industries’s indepth report builder enables user inter-activity, and delivers a wonderful admin experience.",
  },
  {
    id: 2,
    title: "Personalized Dashboards",
    description:
      "Visualization of business data, monitor complete performance, and make correct business decisions.",
  },
  {
    id: 3,
    title: "Based on Data Science & ML",
    description:
      "Our solutions are based on data science & ML core engines, Parag Kosurkar Industries empowers business management to predict future possibilities and take valuable decisions.",
  },
  {
    id: 4,
    title: "Real Time Analytics",
    description:
      "Parag Kosurkar Industries enables you to analyze real-time data coming from industry sources without any time lag, and monitor your operations as they operating.",
  },
  {
    id: 5,
    title: "Online analytical processing",
    description:
      "Sub-second, multi-dimensional Online analytical processing reporting allows the admin explore all your data in a single screen.",
  },
  {
    id: 6,
    title: "Trends & Forecasting by ML",
    description:
      "Trends & forecasting by Parag Kosurkar Industries, Admin can add live reference options to visuals, and provide solutions for important insights.",
  },
  {
    id: 7,
    title: "Strong Reports",
    description:
      "Parag Kosurkar Industries empowers company to create perfect reports from invoices, labels to ledgers and audit reports, to enhance organization’s operations.",
  },
  {
    id: 8,
    title: "Network Connectivity",
    description:
      "Parag Kosurkar Industries allows easy connect and delivers in data from various sources.",
  },
];
const cards1 = [
  {
    id: 1,
    title: "Medical Device Networking",
    description:
      "• Bulk of data can be analysed in short tim • Customer resolution leads to increased revenue • Gain competitive advantage • Accelerate successful solution of value-based care",
  },
  {
    id: 2,
    title: "Operations",
    description:
      "• Accelerate successful adoption of value-based care • Increase frontline staff productivity• Achieve operational excellence• Reduce Costs.",
  },
  {
    id: 3,
    title: "Clinical management",
    description:
      "• Accelerate successful adoption of analytical based care system • Increase staff productivity• Achieve clinical excellence • Reduce Costs in entire clinical management",
  },
  {
    id: 4,
    title: "Finance",
    description:
      "• Shorten Reimbursement time • Improve financial performance and operational profit • Prevent claim denials",
  },
];
const customeCard = [
  {
    id: 1,
    title: "Data transformation",
    image: bank.card1,
    description:
      "Accelerate “Data-to-Insight-to-Action” cycle, by consuming offerings like Data-as-a-Service and Reporting-as-a-Service.",
  },
  {
    id: 2,
    title: "Digital enablement",
    image: bank.card2,
    description:
      "Drive key business outcomes, using the full service digital stack – Mosaic, Digital Jedis, and human-centered design experience.",
  },
  {
    id: 3,
    title: "Innovation partnership",
    image: bank.card3,
    description:
      "Adopt a collaborative approach to innovation, by leveraging innovation labs, future financial ecosystems, alliances & partners.",
  },
];

const Healthcare = () => {
  return (
    <>
      <div>
        <img className="img" src={backImg} />
        <h2 className="heading">
          Expert IT services for the healthcare Industry
        </h2>
      </div>
      <p className="insurance-para">
        Healthcare Business Analytics unleash the full potential of healthcare
        industry, making it quick and easy to get insights out of the hospital
        data that leads every employee from clinic to specialist to hospital.
        Healthcare analytics is delivering right decision making and caring in
        the healthcare industry. The Parag Kosurkar Industries’s Analytics Platform helps unleash
        the strength of data across institutions with healthcare data analytics
        by our software.Parag Kosurkar Industries’s Analytics app for non-technical type of user,
        with the ability to understand insights directly with business workflow.
      </p>

      <div>
        <h4 className="heading-2">
          Key features that can be Avail by Parag Kosurkar Industries
        </h4>
        <div className="insurance-card-section">
          {cards.map((card) => (
            <div key={card.id} className="insurance-card">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h4 className="heading-2">Types of Enterprise Medical Software</h4>
        <div className="insurance-card-section">
          {cards1.map((card) => (
            <div key={card.id} className="insurance-card">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <p className="heading-3">
          Transform your technology by focusing on 3 key areas
        </p>
        <div className="custome-card-section">
          {customeCard.map((card) => (
            <div className="custome-card" key={card.id}>
              <img
                className="custome-img"
                src={card.image}
                alt={`Card ${card.id}`}
              />
              <p className="custome-title">{card.title}</p>
              <p className="custome-description">{card.description}</p>
            </div>
          ))}
        </div>
      </div>

    </>
  );
};



export default Healthcare;
