import React, { useState } from 'react';
import img from '../AboutPages/about-images/choose_us.png';
import benefitImage1 from '../AboutPages/about-images/health.png';
import benefitImage2 from '../AboutPages/about-images/flexibility.png';
import benefitImage3 from '../AboutPages/about-images/meal.png';
import benefitImage4 from '../AboutPages/about-images/fun.png';
import benefitImage5 from '../AboutPages/about-images/gym.png';


const CareerPage = () => {
    const faqs = [
        {
            question: "Are free Anti-Virus software any good?",
            answer: "First and foremost, you never want to go without security protection on your computer. Free Anti-Virus has very low detection rates. Give us a call and we will be happy to inform you of the latest security software we recommend and sell to all our clients for Spyware, Malware and Virus protection."
        },
        {
            question: "What exactly are Managed IT Services?",
            answer: "Simply put, Parag Kosurkar Industries Managed IT Services means we take care of your entire information technology requirement. We manage all your hardware and software sourcing, installation, technical support, and IT staffing needs. It also means NanoSoft acts as your go-to consultancy and support team, providing scheduled maintenance and upgrading of your systems, along with emergency assistance to keep your business up and running."
        },
        {
            question: "What is cloud backup?",
            answer: "Cloud backup also known as Online Backup is the process where your onsite backups are transferred to an offsite server every night. The server is located in a secure data centre in Perth. Cloud Backup replaces the need for someone to take a backup home each night. It is more secure, reliable and easier to manage and monitor."
        },  {
            question: "What kind of response times can I expect?",
            answer: "We work with each client to establish specific expectations. Our measurable service levels specify clear consequences for not living up to agreed-upon expectations."
        },  {
            question: "How Long is a Managed Services Contract For?",
            answer: "Managed IT Services Contracts vary by provider. Some providers offer month-to-month programs, while others require a multi-year contract. Some have a very high startup cost and lower monthly, while others require a multi-year contract. Some have a very high startup cost and lower monthly, while others offer a middle of the road monthly cost and spread the cost of startup over the term of the agreement."
        },
        {
            question: "What should I do before I call for help?",
            answer: "When possible, write down any information about error messages and take screen shots your issue. Next, attempt to recreate the issue. Often times, it helps to close the program and restart the computer to reset the system, and possibly resolve the problem."
        },
        {
            question: "What does having Managed IT Services cost?",
            answer: "Our service model uses a fixed monthly fee, which is based on the size and complexity of your particular network. Once contracted, your Managed IT Services will cover maintenance and support for every component of your network, providing you with peace of mind and the ability to accurately forecast your IT maintenance costs."
        },
        {
            question: "What if we already have an internal IT department?",
            answer: "No problem! We offer scalable solutions that can be tailored to meet your specific needs. Whether you need a full package of managed IT services and consulting, security solutions or service desk support, Parag Kosurkar Industries makes it simple with an affordable and customized flat rate service plan."
        },
        {
            question: "How does a flat rate billing save me money?",
            answer: "Flat-rate billing gives you the ability to budget your IT expenses so you can better focus on your core business goals. We customize each service package for your unique business, so you only pay for what you need. Parag Kosurkar Industries serves as your “one stop shop” for all your managed IT services needs. And we do it all for one fixed monthly cost – We Don’t Profit from your Pain!"
        },
        {
            question: "What types of systems do you support?",
            answer: "We pride ourselves on being “ecosystem agnostic”: whether you use Google Apps or Office365, Windows or Mac, Android or iOS, we will support your team. Need to install a server onsite or host one virtually on Amazon or Azure? We will support you. Need to transition from one ecosystem to another? We’ll be there for you."
        },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
 
    return (
        <div className="career-page">
 <style>
    {`
    
    .career-page {
        font-family: Arial, sans-serif;
        text-align: center;
        
    }


    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    /* Polaroid Card Section */
    .polaroid-card {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .card {
        position: relative;
        width: 600px;
        perspective: 1000px;
        margin: 0 10px;
        overflow: hidden; /* Ensures content stays within the card */
        border-radius: 8px; /* Optional: rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow effect */
    }

    .card .image {
        position: relative;
        overflow: hidden;
    }

    .card .image img {
        width: 100%;
        height: auto;
        display: block;
        transition: transform 0.5s ease;
    }

    .card .content {
        background-color: #fff;
        padding: 20px; /* Adjust padding as needed */
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
        transition: transform 0.5s ease;
        box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow effect */
    }

    .card:hover .content {
        transform: translateY(0); /* Slide into view */
    }

    /* Other sections */
    .current-openings {
        text-align: center;
        margin-top: 100px;
    }

    .current-openings h2 {
        font-size: 2rem;
        margin-bottom: 10px;
       
    }

    .current-openings p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .openings-table {
        margin: 0 auto;
        border-collapse: collapse;
        width: 80%;
    }

    .openings-table th, .openings-table td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: left;
    }

    .openings-table th {
        background-color: #f2f2f2;
        font-weight: bold;  
    }

    .openings-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }
    .openings-table tr td a{
        color:black;
    }
    .openings-table tr td a:hover{
        color:red;
    }

    .role-details {
        font-size: 1.1rem;
        font-weight: bold;
        padding: 3px;
    }

    .role-qualifications {
        font-size: 1rem;
        padding: 3px;
    }

    .role-experience {
        font-size: 0.9rem;
        color: #666;
    }

    .benefits-section {
        margin: 100px auto;
        max-width: 1200px;
    }

    .benefits-heading {
        font-size: 2rem;
        margin-bottom: 20px;
     
    }

    .benefits-para {
        font-size: 1.2rem;
    }

    .benefits-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .benefit-card {
        width: calc(30% - 20px);
        margin: 10px;
        border: 1px solid #ddd;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 5px;
        overflow: hidden;
    }

    .benefit-card:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .benefit-card img {
        width: 100%;
        height: auto;
        display: block;
    }

    .benefit-card-content {
        padding: 10px;
        text-align: center;
    }

    .benefit-card-content h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .benefit-card-content p {
        font-size: 1rem;
        color: #666;
    }

    .faq-section {
        margin-top: 80px;
        padding: 20px;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    .faq-heading {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .faq-list {
        display: flex;
        flex-direction: column;
    }

    .faq-item {
        margin-bottom: 10px;
        cursor: pointer;
    }

    .faq-question {
        font-weight: bold;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        transition: background-color 0.3s ease;
    }

    .faq-question:hover {
        background-color: #f2f2f2;
    }

    .faq-question.active {
        background-color: #f2f2f2;
    }

    .faq-answer {
        padding: 10px;
        display: none;
    }

    .faq-answer.show {
        display: block;
    }

    /* Responsive Styles */
     @media (min-width: 1024px) {
                    .current-openings h2,
                    .benefits-heading,
                    .faq-heading {
                        border-bottom: 2px solid black; /* Border for h2 elements */
                        padding-bottom: 5px; /* Padding for the border */
                         display: inline-block;
                         
                    }
                }

    @media (max-width: 768px) {
        .benefits-cards {
            flex-direction: column;
            align-items: center;
        }

        .benefit-card {
            width: 80%;
            margin: 10px 0;
        }

        .openings-table {
            width: 100%;
        }


        .current-openings h2 {
            font-size: 1.5rem;
        }

        .current-openings p {
            font-size: 1rem;
        }
    }

    @media (max-width: 480px) {
        .benefits-heading {
            font-size: 1.5rem;
        }

        .benefits-para {
            font-size: 1rem;
        }

        .openings-table th, .openings-table td {
            font-size: 0.9rem;
            padding: 8px;
        }

        .faq-heading {
            font-size: 1.5rem;
        }

        .faq-question, .faq-answer {
            font-size: 0.9rem;
        }
    }
    `}
</style>


<div>
  <div style={styles.whyChooseUs}>
    <div style={styles.imageContainer}>
      <img src={img} alt="Background" style={styles.image} />
      <div style={styles.overlay}></div> {/* Dark Overlay */}
      <div style={styles.overlayText}>
        <h1 style={styles.paragraph1}>Work With Us</h1>
        <p style={styles.paragraph}>
        We help companies reach their full potential. Are you ready to reach yours? Come join us.
        </p>
      </div>
    </div>
  </div>
</div>
           

            <div className="current-openings">
                <h2>Current Openings</h2>
                <p>Explore the opportunities below and apply to become a part of our team.</p>
                <table className="openings-table">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Location</th>
                            <th>Type</th>
                            <th>Apply</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="role-details">B.D.A. (Business Development Associate)</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="role-details">PHP Developer</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="role-details">Junior Cloud Developer</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="role-details">Business Analyst</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="role-details">Data Analyst</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="role-details">Data Scientist</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="role-details">Jr.Data Scientist</div>
                                <div className="role-qualifications">Qualification:- B.E. / M. B. A.</div>
                                <div className="role-experience">Experience:- 0 - 3 Years</div>
                            </td>
                            <td>India</td>
                            <td>Full-Time</td>
                            <td><a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2hvFxBSJHChaqenWSOp-LbXF9hPu7eNXGBnjaX4qiWS21sg/formResponse">Apply</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="benefits-section">
                <h2 className="benefits-heading">Employee Benefits</h2>
                <p className="benefits-para">We offer a variety of benefits to our employees to ensure their well-being and happiness.</p>
                <div className="benefits-cards">
                    <div className="benefit-card">
                        <img src={benefitImage1} alt="Healthcare" />
                        <div className="benefit-card-content">
                            <h3>Healthcare</h3>
                            <p>Comprehensive health care plans to keep you and your family healthy.</p>
                        </div>
                    </div>
                    <div className="benefit-card">
                        <img src={benefitImage2} alt="Flexibility" />
                        <div className="benefit-card-content">
                            <h3>Flexibility</h3>
                            <p>Flexible work hours and remote work options to maintain work-life balance.</p>
                        </div>
                    </div>
                    <div className="benefit-card">
                        <img src={benefitImage3} alt="Catered Meals" />
                        <div className="benefit-card-content">
                            <h3>Catered Meals</h3>
                            <p>Enjoy delicious catered meals during office hours.</p>
                        </div>
                    </div>
                    <div className="benefit-card">
                        <img src={benefitImage4} alt="Team-building Activities" />
                        <div className="benefit-card-content">
                            <h3>Team-building Activities</h3>
                            <p>Engage in fun and productive team-building activities regularly.</p>
                        </div>
                    </div>
                    <div className="benefit-card">
                        <img src={benefitImage5} alt="Gym Membership" />
                        <div className="benefit-card-content">
                            <h3>Gym Membership</h3>
                            <p>Access to gym facilities to stay fit and healthy.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faq-section">
                <h2 className="faq-heading">Frequently Asked Questions</h2>
                <div className="faq-list">
                    {faqs.map((faq, index) => (
                        <div className="faq-item" key={index}>
                            <div className={`faq-question ${activeIndex === index ? 'active' : ''}` } onClick={() => toggleFAQ(index)}>
                                {faq.question}
                            </div>
                            <div className={ `faq-answer ${activeIndex === index ? 'show' : 'hide'} `}>
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
      

            
        </div>
    );
}

const styles = {
    whyChooseUs: {
      position: 'relative',
      width: '100%',
      height: '700px', // Adjust height as needed
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', // Center horizontally
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    image: {
      width: '100%',
      height: '90%',
      objectFit: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '90%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker overlay
      zIndex: 1,
    },
    overlayText: {
      position: 'relative',
      color: 'white',
      zIndex: 2,
      textAlign: 'center', // Center text horizontally
      padding: '20px', // Adjust padding as needed
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '90%',
    },
    paragraph1: {
      margin: '0',
      fontSize: '2.5rem',
      opacity: 0,
      animation: 'fadeIn 1s ease-in-out forwards',
      animationDelay: '1s',
    },
    paragraph: {
      margin: '10px 0 0 0',
      fontSize: '1.5rem',
      opacity: 0,
      animation: 'fadeIn 1s ease-in-out forwards',
      animationDelay: '1s',
    },
    
    
  };
  
  const mediaStyles = {
    '@media (max-width: 768px)': {
      whyChooseUs: {
        height: '400px', // Adjust height for mobile
      },
      image: {
        height: '100%', // Ensure the image covers the full height on mobile
      },
      overlay: {
        height: '100%', // Ensure the overlay covers the full height on mobile
      },
      overlayText: {
        padding: '10px', // Reduce padding for mobile
      },
      paragraph1: {
        fontSize: '1.5rem', // Adjust font size for mobile
      },
      paragraph: {
        fontSize: '1rem', // Adjust font size for mobile
      },
    },
  };
  
  // Combine styles and mediaStyles
  const combinedStyles = { ...styles, ...mediaStyles };
  

export default CareerPage;