import React, { useEffect, useState } from "react";
import backgroundImg from "../AboutPages/about-images/mission.png";
import undrawImage1 from "../AboutPages/about-images/culture.png";
import undrawImage2 from "../AboutPages/about-images/commit.png";
import undrawImage3 from "../AboutPages/about-images/miss.png";
import undrawImage4 from "../AboutPages/about-images/vision.png";
import undrawImage5 from "../AboutPages/about-images/values.png";

const Mission = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const sections = [
    {
      image: undrawImage1,
      heading: "Our Culture",
      paragraph:
        "We communicate clearly, because in a world crowded with over-the-top marketing, simple wins. We are not rock stars, ninjas or any other disingenuous buzz term. We are creative professionals. Before you can market a business, you need to know its story.",
    },
    {
      image: undrawImage2,
      heading: "Our Commitment",
      paragraph:
        "We’re the trusted partner that takes a proactive approach to your technology. We’re the fellow small business owner that understand your day-to-day needs. We’re the built-in IT support staff that’s always there when you need us.",
    },
    {
      image: undrawImage3,
      heading: "Our Mission",
      paragraph:
        "To be the global leader in IT Services. Building on our technologies, competencies and customer interests, and creating value for our customers. We’ll achieve this by focusing on the intersection of our client’s emerging needs and the acceleration of business and technological change.",
    },
    {
      image: undrawImage4,
      heading: "Our Vision",
      paragraph:
        "Parag Kosurkar Industries will be the first choice solution partner in the technology sector providing IT and training services to businesses throughout the region. We will become an integral part of our client’s success, collaborating with them to achieve their strategic objectives whilst creating long lasting business value through the delivery and management of their technology.",
    },
    {
      image: undrawImage5,
      heading: "Our Values",
      paragraph:
        "Our values are the guiding principles upon which Parag Kosurkar Industries was founded and how we strive to conduct our business on a daily basis. Values establish our view of the world as we shape the future. They determine how we treat each other. Our values are to:",
    },
    {
      heading: "Leadership Teams",
      paragraph:
        "Parag Kosurkar Industries leadership team has a long history of success in technology, business management, and franchising.",
      backgroundColor: "#f0f0f0",
    },
  ];

  return (
    <div>
      <div style={styles.missionPage}>
        <div style={styles.imageContainer}>
          <img src={backgroundImg} alt="Background" style={styles.image} />
        </div>
        <div style={styles.overlay}>
          <div style={styles.dialogBox}>
            <h2 style={styles.heading}>Mission, Vision & Values</h2>
            <p style={styles.paragraph}>
              We connect with our clients, our communities, and our company. It
              makes us different. It makes us better. We are not rock stars,
              ninjas or any other disingenuous buzz term. We are creative
              professionals. Before you can market a business, you need to know
              its story.
            </p>
          </div>
        </div>
      </div>

      <div style={styles.newSection}>
        {sections.map((section, index) => (
          <div
            key={index}
            style={{
              ...styles.imageTextWrapper,
              backgroundColor: section.backgroundColor || "transparent",
            }}
          >
            {section.image && (
              <img
                src={section.image}
                alt={`Illustration ${index + 1}`}
                style={styles.smallImage}
              />
            )}
            <div style={styles.textContainer}>
              <h3 style={styles.sectionHeading}>{section.heading}</h3>
              <p style={styles.largeSectionParagraph}>{section.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  missionPage: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1,
  },
  dialogBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '90%',
    margin: '0 10px',
    textAlign: 'center',
    color: '#fff',
  },
  heading: {
    fontSize: '2rem',
    margin: '0 0 15px 0',
  },
  paragraph: {
    fontSize: '1rem',
    margin: '0',
  },
  newSection: {
    padding: '20px',
    backgroundColor: "#f8f9fa",
  },
  imageTextWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
  },
  smallImage: {
    width: "80%",
    maxWidth: "500px",
    height: "auto",
    marginBottom: "15px",
  },
  textContainer: {
    textAlign: "center",
    padding: "0 20px",
  },
  sectionHeading: {
    fontSize: "1.5rem",
    marginBottom: "10px",
    position: "relative",
    display: "inline-block",
    paddingBottom: "5px",
    borderBottom: "2px solid #000", // Adjust underline style here
  },
  largeSectionParagraph: {
    fontSize: "1rem",
    maxWidth: "800px",
    margin: "0 auto", // Center align for larger screens
    lineHeight: "1.6", // Improve readability
    textAlign: "center",
  },
  '@media (min-width: 1024px)': { // Styles for laptop screens and up
    largeSectionParagraph: {
      textAlign: "left", // Align text to the left on larger screens
      margin: "0 20px", // Adjust margins for larger screens
    },
  },
};

export default Mission;
