import React from 'react';
import { bank } from '../../assets/BankFinance/bank';
import '../../Css/Bankfinance.css'

const BankFinance = () => {

    const customeCard = [
        {
            id: 1,
            title: "Data transformation",
            image: bank.card1,
            description: 'Accelerate “Data-to-Insight-to-Action” cycle, by consuming offerings like Data-as-a-Service and Reporting-as-a-Service.',
        },
        {
            id: 2,
            title: "Digital enablement",
            image: bank.card2,
            description: 'Drive key business outcomes, using the full service digital stack – Mosaic, Digital Jedis, and human-centered design experience.',
        },
        {
            id: 3,
            title: "Innovation partnership",
            image: bank.card3,
            description: 'Adopt a collaborative approach to innovation, by leveraging innovation labs, future financial ecosystems, alliances & partners.',
        },

    ]
    return (
        <>
            <div>
                <h2 className='h2'>Expert IT services for the banking Industry</h2>
            </div>
            <div className='bank-card'>
                <div className='left-card'>
                    <p>Faced with increasing competition, banking and financial services organizations must move quickly to meet new customer, employee and industry demands
                        while addressing complex regulatory mandates and heightened security threats.</p>
                </div>
                <div className='right-card'>
                    <ul>
                        <li>To attract and retain customers, they must provide new and more innovative ways to interact with financial services, from online banking and mobile apps, to contact centers, to a more modern in-branch experience.</li>
                        <li>Anytime access to resources and flexible work options are critical for attracting and engaging the most skilled staff.</li>
                        <li>As markets evolve and emerge, they need the agility to grow, shift markets, outsource, acquire and divest rapidly, with continuous innovation to stay at the leading edge of global competition.</li>
                    </ul>
                </div>
            </div>
            <p id='para'>
                Behind all these initiatives are the systems and infrastructure powered by Parag Kosurkar Industries. With strengths in every discipline across the entire portfolio of financial IT—including storage area networking, compliance,
                security, disaster recovery planning and more—Parag Kosurkar Industries is your partner in building true enterprise grade IT environments.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
                {/* Card 1 */}
                <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '20px', width: '300px' }}>
                    <img src={bank.bank1} alt="Card 1" style={{ marginBottom: '10px', borderRadius: '8px', width: '100%' }} />
                    <h3>Retail banking</h3>
                    <p>Enable superior digital experiences, by modernizing legacy systems and leveraging APIs, DevOps, and FinTech partnership ecosystem.</p>
                </div>

                {/* Card 2 */}
                <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '20px', width: '300px' }}>
                    <img src={bank.bank2} alt="Card 2" style={{ marginBottom: '10px', borderRadius: '8px', width: '100%' }} />
                    <h3>Cards and payments</h3>
                    <p>Enable simple, frictionless, and compliant payments experience, by leveraging open API, digital payments, blockchain, and analytics.</p>
                </div>

                {/* Card 3 */}
                <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '20px', width: '300px' }}>
                    <img src={bank.bank3} alt="Card 3" style={{ marginBottom: '10px', borderRadius: '8px', width: '100%' }} />
                    <h3>Corporate Banking</h3>
                    <p>Transform your business to align to the future of the trade finance industry, by leveraging blockchain, cloud, automation, and analytics.</p>
                </div>

                {/* Card 4 */}
                <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '20px', width: '300px' }}>
                    <img src={bank.bank4} alt="Card 4" style={{ marginBottom: '10px', borderRadius: '8px', width: '100%' }} />
                    <h3>Investment banking</h3>
                    <p>Drive operational efficiencies and improve experience, by bringing a domain-centric middle and back-office transformation approach.</p>
                </div>

                {/* Card 5 */}
                <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '20px', width: '300px' }}>
                    <img src={bank.bank5} alt="Card 5" style={{ marginBottom: '10px', borderRadius: '8px', width: '100%' }} />
                    <h3>Custody and fund services</h3>
                    <p>Adopt a platform-led approach, to drive automation and reduce costs, by consuming services in an as-a-service model.</p>
                </div>

                {/* Card 6 */}
                <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '20px', width: '300px' }}>
                    <img src={bank.bank6} alt="Card 6" style={{ marginBottom: '10px', borderRadius: '8px', width: '100%' }} />
                    <h3>Risk and compliance</h3>
                    <p>Gain competitive advantage from risk & compliance efforts, with risk reporting, AML transformation, and product partners.</p>
                </div>

                <div>
                    <p className="heading-3">Transform your technology by focusing on 3 key areas</p>
                    <div className="custome-card-section">
                        {customeCard.map((card) => (
                            <div className="custome-card" key={card.id}>
                                <img className="custome-img" src={card.image} alt={`Card ${card.id}`} />
                                <p className="custome-title">{card.title}</p>
                                <p className="custome-description">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>




            </div>


        </>
    );
};

export default BankFinance;
