import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Final } from '../assets/Final.js';
import '../Css/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = (index) => {
    setActiveLink(index);
    // Close the menu on mobile when a link is clicked
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link className='logo-main' to="/" onClick={() => setActiveLink(null)}>
          <img src={Final.saplogo} alt="Logo" /><span>Industries</span>
          </Link>
        </div>
        <button className="navbar-toggle" onClick={handleMenuToggle}>
          {isMenuOpen ? <IoMdClose /> : <span>&#9776;</span>}
        </button>
        <ul className={`navbar-menu ${isMenuOpen ? 'navbar-menu-open' : ''}`}>
          <li className="navbar-item">
            <Link to="/" className={` ${activeLink === 0 ? 'active' : ''}`} onClick={() => handleNavLinkClick(0)}>Home</Link>
          </li>
          <li className="navbar-item navbar-dropdown-container">
            <div className="navbar-dropdown">
              <Link to='/about'>About &#9662;</Link>
              <ul className="dropdown-menu">
                <li className='navbar-dropdown-container'>
                  <div className='link-dropdown'><button className='btn'><Link to='/mission' style={{ color: 'black', textDecoration: 'none' }}> Mission, Vision & Values </Link></button></div>
                  <div className='link-dropdown'><button className='btn'><Link to='/chooseus' style={{ color: 'black', textDecoration: 'none' }}>Why Choose Us</Link></button></div>
                  <div className='link-dropdown'><button className='btn'><Link to='/careers' style={{ color: 'black', textDecoration: 'none' }}>Carrers</Link></button></div>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar-item navbar-dropdown-container">
            <div className="navbar-dropdown">
              <Link>IT Solutions &#9662;</Link>
              <ul className="dropdown-menu">
                <li className='navbar-dropdown-container'>
                  <div className='link-dropdown'><button className='btn link-dropdown'>IT Services</button>
                    <ul className='link-dropdown-menu'>
                      <li><Link to='/softwaredev'>Software Development</Link></li>
                      <li><Link to='/applicationdev'>Application Development</Link></li>
                      <li><Link to='/websitedev'>Web Site Development</Link></li>
                      <li><Link to='/salesforce'>Salesforce</Link></li>
                      <li><Link to='/devops'>DevOps</Link></li>
                      <li><Link to='/digitalmarketing'>Digital Marketing &SEO</Link></li>
                      <li><a href="https://sapalogy.com/cyber_security.html" target="_blank" rel="noopener noreferrer">Cyber Security</a></li>
                    </ul>
                  </div>
                  <div className='link-dropdown'><button className='btn link-dropdown'>Enterprices Solution</button>
                    <ul className='link-dropdown-menu'>
                      <li><Link to='/bankfinance' >Banking & Finance</Link></li>
                      <li><Link to="/insurance">Insurance</Link></li>
                      <li><Link to="/manufacturing">Manufacturing</Link></li>
                      <li><Link to="/healthcare">Healthcare</Link></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar-item"><a href='https://sapalogypvtltd.wordpress.com/'>IT Blog</a></li>
          <li className="navbar-item">
            <Link to='/contactus' className={` ${activeLink === 4 ? 'active' : ''}`} onClick={() => handleNavLinkClick(4)}>Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
