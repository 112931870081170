import React from 'react';
import '../ItServices/DevOps.css';
import TopImage from '../../../assets/A.webp'; // Import the top image
import CardImage1 from '../../../assets/Card1.webp'; // Import the images for the cards
import CardImage2 from '../../../assets/Card2.webp';
import CardImage3 from '../../../assets/Card3.webp';
import CardImage4 from '../../../assets/Card4.webp';
import CardImage5 from '../../../assets/Card5.webp'; // Adjust the path according to your file structure

const DevOps = () => {
  return (
    <div className="new-page">
      <img src={TopImage} alt="Top" className="top" />
      <div className="cards-container">
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <img src={CardImage1} alt="Card 1" />
              <div className="card-content">
                <h3>Continuous Integration and Deployment (CI/CD)</h3>
              </div>
            </div>
            <div className="card-back">
              <p>
              Implement automated CI/CD pipelines to speed up software delivery while maintaining quality and reliability.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <img src={CardImage2} alt="Card 2" />
              <div className="card-content">
                <h3>Infrastructure as Code (IaC)</h3>
              </div>
            </div>
            <div className="card-back">
              <p>
              Automate infrastructure provisioning and management using tools like Terraform and Ansible, ensuring consistency and scalability.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <img src={CardImage3} alt="Card 3" />
              <div className="card-content">
                <h3>Containerization and Orchestration</h3>
              </div>
            </div>
            <div className="card-back">
              <p>
              Harness the power of Docker and Kubernetes to containerize applications and manage them efficiently at scale.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <img src={CardImage4} alt="Card 4" />
              <div className="card-content">
                <h3>Monitoring and Logging
                </h3>
              </div>
            </div>
            <div className="card-back">
              <p>
              Set up robust monitoring and logging solutions (e.g., Prometheus, ELK stack) to gain insights into system performance and health.


              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <img src={CardImage5} alt="Card 5" />
              <div className="card-content">
                <h3>Cloud Migration and Optimization</h3>
              </div>
            </div>
            <div className="card-back">
              <p>
              Seamlessly migrate applications to the cloud (AWS, Azure, Google Cloud) and optimize cloud infrastructure for cost-efficiency and performance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="points-container">
        <h2 className="main-heading">Why Choose Parag Kosurkar Industries for DevOps?</h2>
        <div className="point" data-aos="fade-up" data-aos-duration="1000">
          <h3>Expertise</h3>
          <p>Our DevOps engineers bring deep expertise in modern tools and methodologies to drive your DevOps initiatives.</p>
        </div>
        <div className="point" data-aos="fade-up" data-aos-duration="1000">
          <h3>End-to-End Solutions</h3>
          <p>From strategy and implementation to ongoing support, we offer comprehensive DevOps solutions tailored to your needs.</p>
        </div>
        <div className="point" data-aos="fade-up" data-aos-duration="1000">
          <h3>Continuous Improvement</h3>
          <p>We foster a culture of continuous improvement, enabling you to adapt quickly to changing business requirements.</p>
        </div>
        <div className="point" data-aos="fade-up" data-aos-duration="1000">
          <h3>Security and Compliance</h3>
          <p>We prioritize security best practices and compliance standards to safeguard your applications and data.</p>
        </div>
      </div>
    </div>
  );
};

export default DevOps;
