import React from 'react'
import '../Css/Hero-section.css'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import HomeBGVideo from '../assets/Kuchbhi.png'
import HomeBGImage from '../assets/Hero.webp' // Ensure this path is correct

const Hero = () => {

    useGSAP(() => {
        gsap.from("h1", {
            opacity: 0,
            xPercent: 400,
            stagger: 0.2,
            duration: 2,
        })
    })

    return (
        <div>
            <img src={HomeBGVideo} className='background-video' />
            <img className='background-image' src={HomeBGImage} alt="Background" />
            <div className="text-animate">
                <div className='main-animate'>
                    <h1 className='h1'>Parag Kosurkar Industries</h1>
                    <p className='tagline'>Is your trusted source in IT Services and Support</p>
                </div>
                <p className='para'>Parag Kosurkar Industries is a privately owned IT Support and IT Services business formed in 2012.
                    Today we’re proud to boast a strong team of IT engineers who thrive on rolling up their sleeves and solving your IT
                    problems and meeting your business needs.
                </p>
            </div>
        </div>
    )
}

export default Hero
