import React from 'react'
import Card from '../Components/Card'
import Review from '../Components/Review'
import Hero from '../Components/Hero-section'


const Home = () => {
  return (
    <div>
      <Hero/>
      <Card/>
      <Review/>
    </div>
  )
}

export default Home
