import React from "react";
import DMHOME from '../../../assets/DM-IMG.webp';
import '../../../Css/DigitalMarketing.css';
import ROI from '../../../assets/ROI.webp';
import Track from '../../../assets/Track.webp';
import Experts from '../../../assets/Experts.webp';
import Traffic from '../../../assets/20.webp'
import Advertising from '../../../assets/21.webp'
import Social from '../../../assets/22.webp'

const points = [
  {
    title: "Transparent ROI Tracking",
    description: "No need to wade through complicated spreadsheets when evaluating your campaign with Parag Kosurkar Industries. All clients have access to MarketingCloudFX, our lead, call, and ecommerce tracking software.",
    image: ROI
  },
  {
    title: "Unparalleled Track Record",
    description: "We have a 91% client retention rate and a client recommendation score that's 300% higher than the nationwide average. Our 50+ client testimonials, overflowing trophy case are the perfect recipe for a successful digital campaign.",
    image: Track
  },
  {
    title: "Leading Digital Experts",
    description: "We've been designing and marketing websites since 2012. We keep tabs on the latest and greatest design trends but measure the success of any site launch by how well the new design is able to impact business goals.",
    image: Experts
  },

];

const additionalPoints = [
  {
    title: "Earning Traffic ",
    description: "A website without traffic is worthless but a website with the right traffic flowing in consistently can propel a business to new heights. Our site more visible to your key market through our comprehensive digital marketing services.",
    image: Traffic 
  },
  {
    title: "Measurable Advertising",
    description: "The right advertising campaign can be the secret sauce to a business' success. By focusing on ROI from the starting line, our marketing team crafts PPC, display, geotargeted and remarketing campaigns engineered for success.",
    image: Advertising 
  },
  {
    title: "Expand Your Social Reach",
    description: "Connectivity and online interaction is a must for any company focused on growth. Our social media team are experts in creating and executing a social media management plan that gets the right message in front of your desired audience.",
    image: Social 
  },
];

const DigitalMarketing = () => {
  return (
    <>
      <img className="DM-IMG" src={DMHOME} alt="Digital Marketing Home" />
      <div>
        <h1 className="dm-heading">Our Full-Service Web Solutions Help Your Business Grow Online Leads, Calls, And Revenue.</h1>
        <p className="dm-para">Parag Kosurkar Industries is a full-service Internet marketing and SEO company offering innovative web marketing solutions to mid to large size companies across the globe. As a leader in SEO, web design, ecommerce, website conversion, and Internet marketing services, our firm prides ourselves on driving traffic, converting visitors, and measuring effectiveness to ultimately deliver real results for our clients.</p>

      </div>
      <div className="container-card">
        {points.map((point, index) => (
          <div className="dm-container" key={index} style={{ animationDelay: `${index * 0.2}s` }}>
            <div className="dm-image">
              <img src={point.image} alt={point.title} />
            </div>
            <div className="dm-content">
              <h4>{point.title}</h4>
              <p>{point.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="additional-section">
        <h2 className="additional-heading"> How Parag Kosurkar Industries Creates Your Business Growth</h2>
        <div className="grid-container">
          {additionalPoints.map((point, index) => (
            <div className="grid-item" key={index}>
              <div className="grid-image">
                <img src={point.image} alt={point.title} />
              </div>
              <div className="grid-content">
                <h4>{point.title}</h4>
                <p>{point.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default DigitalMarketing;
