import React from 'react';
import Marquee from 'react-fast-marquee';

const MarqueeTopbar = () => {
  return (
    <div style={{ background: '#2C2E3A', color: '#fff', padding: '10px 0' }}>
      <Marquee gradient={false} speed={90}>
        Welcome to Parag kosurkar industries | 607, 608 B-wing, Lokmat Bhavan, Lokmat Square, Ramdaspeth, Nagpur, Maharashtra 440012 | +91-9175978889
      </Marquee>
    </div>
  );
};

export default MarqueeTopbar;
