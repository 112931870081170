import React from "react";
import backImg from "../../assets/BankFinance/Insurance.webp";
import '../../Css/Insurance.css'
import { bank } from '../../assets/BankFinance/bank'

const cards = [
    { id: 1, title: 'Sales and Distribution', description: 'Stimulate the productivity and performance of insurance sales teams by providing them with instant access to serious company systems and information' },
    { id: 2, title: 'Project Management', description: 'Leverage powerful business analytics to manage risk, portfolios, pricing, and customer attrition rate for better results' },
    { id: 3, title: 'Customer Analysis', description: 'Better serve and engage with customers, build loyalty, and drive revenue growth is the key of success.' },
    { id: 4, title: 'Financial Analysis', description: 'Regulate enterprise performance management, financial reporting processes & improve senior management to have constant access to critical data.' },
    { id: 5, title: 'Audit and Compliance', description: 'Deep understand activities and trends related to audit, regulatory, and disclosure issues of the insurance company.' },
    { id: 6, title: 'Marketing', description: 'Optimize your marketing efforts and evaluate campaigns in real time with business analytics.' },
    { id: 7, title: 'Employee Digital access', description: 'Secure access to offices, systems, and sensitive information by leveraging smartphone-based multi-factor authentication.' },
    { id: 7, title: 'Remote Identity Authentication', description: 'Leverage User’s remote identity authentication capabilities to help prevent policyholder fraud in the system.' },
    { id: 7, title: 'Corporate Banking', description: 'Transform your business to align to the future of the trade finance industry, by leveraging blockchain, cloud, automation, and analytics.' },
];

const customeCard = [
    {
        id: 1,
        title: "Data transformation",
        image: bank.card1,
        description: 'Accelerate “Data-to-Insight-to-Action” cycle, by consuming offerings like Data-as-a-Service and Reporting-as-a-Service.',
    },
    {
        id: 2,
        title: "Digital enablement",
        image: bank.card2,
        description: 'Drive key business outcomes, using the full service digital stack – Mosaic, Digital Jedis, and human-centered design experience.',
    },
    {
        id: 3,
        title: "Innovation partnership",
        image: bank.card3,
        description: 'Adopt a collaborative approach to innovation, by leveraging innovation labs, future financial ecosystems, alliances & partners.',
    },

]
const Insurance = () => {
    return (
        <>
            <div>
                <img className="img" src={backImg} />
                <h2 className="heading">Software solutions for insurance</h2>
            </div>
            <p className="insurance-para">The new Insurance distribution channels in the insurance
                market has led to increased value parity among providers. The customer start
                differentiating based on the variety and quality of their product and services offerings.
                It's essential that insurance companies deliver quickly process claims, well managed risk
                and complying to government regulatory requirements. The Intelligent Enterprise
                harnesses the power of data science, which is crucial to understanding trends
                and risk exposure of market, streamlining processes, and delivering better customer experience.</p>

            <div>
                <h4 className="heading-2">Key features that can be Avail by Parag Kosurkar Industries</h4>
                <div className="insurance-card-section">
                    {cards.map((card) => (
                        <div key={card.id} className="insurance-card">
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <p className="heading-3">Transform your technology by focusing on 3 key areas</p>
                <div className="custome-card-section">
                    {customeCard.map((card) => (
                        <div className="custome-card" key={card.id}>
                            <img className="custome-img" src={card.image} alt={`Card ${card.id}`} />
                            <p className="custome-title">{card.title}</p>
                            <p className="custome-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Insurance