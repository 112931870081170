// App.js
import React from 'react';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import About from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import MarqueeTopbar from './Components/MarqueeTobar';
import SoftwareDev from './pages/SolutionsPage1/ItServices/SoftwareDev';
import Salesforce from './pages/SolutionsPage1/ItServices/Salesforce';
import WhyChooseUs from './pages/AboutPages/ChooseUs';
import Mission from './pages/AboutPages/Misson';
import Careers from './pages/AboutPages/Careers';
import BankFinance from './pages/Enterprices/BankFinance';
import WebsiteDev from './pages/SolutionsPage1/ItServices/WebsiteDev';
import Insurance from './pages/Enterprices/Insurance';
import Manufacturing from './pages/Enterprices/Manufacturing';
import ApplicationDev from './pages/SolutionsPage1/ItServices/ApplicationDev';
import Healthcare from './pages/Enterprices/Healthcare';
import DevOps from './pages/SolutionsPage1/ItServices/DevOps';
import DigitalMarketing from './pages/SolutionsPage1/ItServices/DigitalMarketing';
import ScrollToTop from './Components/ScrollToTop'; // Import ScrollToTop

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <MarqueeTopbar />
        <Navbar />
        <ScrollToTop /> {/* Add ScrollToTop here */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/softwaredev' element={<SoftwareDev />} />
          <Route path='/applicationdev' element={<ApplicationDev />} />
          <Route path='/websitedev' element={<WebsiteDev />} />
          <Route path='/digitalmarketing' element={<DigitalMarketing />} />
          <Route path='/devops' element={<DevOps />}  />
          <Route path='/salesforce' element={<Salesforce />} />
          <Route path='/chooseus' element={<WhyChooseUs />} />
          <Route path='/mission' element={<Mission />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/bankfinance' element={<BankFinance />} />
          <Route path='/insurance' element={<Insurance />} />
          <Route path='/manufacturing' element={<Manufacturing />} />
          <Route path='/healthcare' element={<Healthcare />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
